<template>
  <div class="useLogPage overflow flex">
    <div class="useLogLeft overflow">
      <li class=" textCenter baseTitle" :class="{ cur: current == index }" v-for="(item, index) in menuArray" :key="index"
        @click="changeMenu(index)">
        {{ item }}
      </li>
    </div>
    <div class="useLogRight overflow">
      <keep-alive>
        <component v-bind:is="currentTabComponent" @collectMethod="collectMethod" :downCount="downCount"></component>
      </keep-alive>
    </div>
  </div>
</template>
<script>
import api from "../../http/api";
export default {
  props: ['downCount'],
  data() {
    return {
      current: 0, //默认选中
      menuArray: ['使用明细', '下载统计'],//左侧导航
      currentTabComponent: 'uselog'
    }
  },
  components: {
      'uselog': () => import('../components/uselog'),
      'usestatistics': () => import('../components/usestatistics'),
  },
  computed: {
  },
  activated(){
    this.current= 0;
    this.currentTabComponent='uselog'
  },
  deactivated(){

  },
  methods: {
    changeMenu(index) {
      this.current = index;
      this.currentTabComponent = index == 0 ? 'uselog' : 'usestatistics'
    },
    rest(data) { //误删，下载回调用到

    },
    collectMethod(data){
      this.$emit('collectMethod', data)
    }
  },
};
</script>
<style lang="less" scoped>

.useLogPage {
  width: 100%;
  height: 100%;
}

.useLogLeft {
  width: 7%;
  background: #DCDCDC;
  height: 100%;
  border: 1px solid #DCDCDC;

  li {
    width: 100%;
    line-height: 26px;
    cursor: pointer;
  }

  .cur {
    background: #fff;
  }

}

.useLogRight {
      width: 93%;
      height: 100%;
      padding-left: 6px;
    }
  
</style>